import modal from "./modal.js";
var Cookies = require('js-cookie');

var quiz = {

	// Quiz container
	quiz: null,

	// Quiz data
	data: null,

	// Quiz answers
	answers: null,

	// Current quiz location
	location: null,

	// Current quiz question
	current: null,

	// Init quiz
	init: function(data)
		{
			this._log('Init');
			this.quiz = document.querySelector('[data-type="quiz"]');
			this.data = data;
			this.answers = Cookies.getJSON('quiz') || {};
			if (typeof this.answers !== 'object')
				this.answers = {};
			this.check();
			this.listeners();
			modal.init();
		},

	// Check quiz status
	check: function()
		{
			this.location = this._location();

			if (this.location === false)
				return;
			
			if (this.location === true) {
				window.location.href = this.quiz.dataset.finish || '/';
				return;
			}

			// Set finished locations
			document.querySelectorAll('[data-location]:nth-child(-n+' + (this.location) + ')').forEach((item) => {
				item.classList.add('finished');
			});

			// Remove active location
			document.querySelectorAll('[data-location].active').forEach((item) => {
				item.classList.remove('active');
			});
			
			
			// Set active location
			document.querySelector('[data-location]:nth-child(' + (this.location + 1) + ')').classList.add('active');
			
			// Init map
			this._map();
		},

	// Question
	question: function(id = false)
		{
			if (!id)
				return;

			// Current location
			let location = this.data[this.location];

			this._log('Location: ' + location.slug + ", question: " + id);

			let question = null;
			if (parseInt(id))
				question = location.points[id - 1];
			else
				location.points.forEach((point) => {
					if (point.slug == id)
						question = point;
				});


			if (!question)
				return;

			let answers = this.answers[location.slug] || {};

			this.current = id;

			let m = document.createElement("div");
			m.innerHTML = document.getElementById('question').innerHTML;

			// Question num
			let num = null;
			if (Object.keys(answers).length === location.points.length)
				num = location.points.length;
			else if (answers[id])
				num = Object.keys(answers).length;
			else 
				num = Object.keys(answers).length + 1;

			// Set progress
			m.querySelector('[data-type="progress"]').style.width = num / location.points.length * 100 + '%';

			// Set image
			m.querySelector('[data-type="image"]').innerHTML = `
				<img src="${question.image}" alt="${question.name}" class="img-responsive">
			`;

			// Set content
			let content = '';
			m.querySelector('[data-type="content"]').innerHTML = `
				<p>Объект ${num} из ${location.points.length}</p>
				<div>
					<h3>${question.name}</h3>
					${question.question}
				</div>
			`;

			let answer = answers[id];
			if (answer) {
				m.querySelectorAll('[data-answer]').forEach((item) => {
					item.classList.add('disabled');
				});

				let current = m.querySelector('[data-answer="' + answer + '"]');
				current.classList.add("active");
			}

			modal.show(m.innerHTML);
		},

	// Answer
	answer: function(answer)
		{
			this._log('Answer: ' + answer + ", question: " + this.current);

			// Current location
			let location = this.data[this.location];
			let answers = this.answers[location.slug] || {};
			answers[this.current] = answer;
			this.answers[location.slug] = answers;

			this.quiz.querySelectorAll('[data-point="' + this.current + '"]').forEach((item) => {
				item.classList.add('answered');
			});

			Cookies.set('quiz', JSON.stringify(this.answers), { expires: 365, path: '/' });
			
			modal.close();
		},

	// Listeners
	listeners: function()
		{
			// Point click
			document.addEventListener('click', (e) => {
				let el = e.target;
				if (el && el.dataset.point) {
					this.question(el.dataset.point);
				}
			});

			// Answer click
			document.addEventListener('click', (e) => {
				let el = e.target;
				if (el && el.dataset.answer) {
					this.answer(el.dataset.answer);
				}
			});

			// Modal closed
			document.addEventListener('modal:closed', () => {
				this._log('Modal closed');

				// Current location
				let location = this.data[this.location];
				let answers = this.answers[location.slug] || {};

				if (Object.keys(answers).length === location.points.length) {
					this._log('Location ' + location.slug + ' finished');
					this.location = this._location();
					this.check();
				}
			});
		},

	// Get current location
	// true - answered all questions
	// false - empty questions config
	// num - current room
	_location: function()
		{
			if (this.data && this.data.length > 0) {
				for (const [index, location] of (this.data || []).entries()) {
					let answers = this.answers[location.slug] || [];
					if (answers !== void(0) && Object.keys(answers).length == location.points.length)
						continue;
					else 
						return index;
				};
				return true;
			}
			return false;
		},

	// Create map
	_map: function()
		{
			// Current location
			let location = this.data[this.location];

			// Set location name
			let header = document.querySelector('[data-type="quiz-title"]')
			if (header)
				header.innerHTML = location.name;

			// Set location description
			let description = document.querySelector('[data-type="quiz-description"]')
			if (description)
				description.innerHTML = location.description || '';

			// Set image
			this.quiz.querySelectorAll('[data-type="quiz-map"] img').forEach((item) => {
				item.src = location.image;
			});

			// Set points
			let points = '';
			let answers = this.answers[location.slug] || {};
			location.points.forEach((point,index) => {
				let i = index + 1;
				points += `
					<a href="javascript: void(0);" style="top: ${point.top}%; left: ${point.left}%;" data-point="${point.slug || i}" class="${(answers[point.slug] || answers[i] ? 'answered' : '')}"><i></i></a>
				`;
			});
			this.quiz.querySelectorAll('[data-type="quiz-map"] div').forEach((item) => {
				item.innerHTML = points;
			});
		},

	// Log
	_log: function(text)
		{
			console.log("[QUIZ] " + text);
		}
}

window.addEventListener('load', () => {
	if (typeof quizData != 'undefined') {
		quiz.init(quizData);
	}
});

// Quiz restart
document.addEventListener('click', (e) => {
	let el = e.target;
	if (el && el.getAttribute('rel') == 'quiz:restart') {
		Cookies.remove('quiz', { path: '/' });
		return true;
	}
});

window.quiz = quiz;
