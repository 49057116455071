export var modal = {

	current: null,

	init: function()
		{
			// Modal ESC close
			document.addEventListener('keyup', (e) => {
				if (e.which === 27) {
					if (document.querySelector('.blocker'))
						this.close();
				}
			});

			// Modal click by close
			document.addEventListener('click', (e) => {
				let el = e.target;
				if (el && el.getAttribute('rel') == 'modal:close') {
					e.preventDefault();
					this.close();
				}
				return false;
			});
		},

	show: function(content)
		{
			if (!content)
				return false;

			if (this.current) {
				this.close();
				setTimeout(() => {
					this.show(content);
				}, 650);
				return false;
			}

			let blocker = document.createElement("div");

			blocker.classList.add('blocker');
			blocker.setAttribute('rel', 'modal:close');

			blocker.innerHTML = content;

			this.current = blocker;

			document.body.appendChild(blocker);
			setTimeout(() => document.body.classList.add('show-modal'), 50);
		},

	close: function()
		{
			if (this.current) {
				document.body.classList.remove('show-modal');
				setTimeout(() => {
					this.current.remove();
					this.current = null;
					document.dispatchEvent(new Event('modal:closed'));
				}, 600);
			}
		},
}

export default modal;