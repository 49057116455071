window.isEmpty = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

window.addEventListener('load', () => {
	let l = window.location;
	let item = document.querySelector('.header nav a[href="' + l.pathname + '"]');
	if (item) {
		item.closest('li').classList.add('active');
	}
});

document.addEventListener('DOMContentLoaded', function(){
	let items = document.querySelectorAll('.content p + ul') || [];
	items.forEach((item) => {
		let p = item.previousElementSibling;
		if (p.innerHTML.slice(-1) === ":") {
			item.style.marginTop = 0;
			p.style.marginBottom = 0;
		}
	});
});

import "./parts/breakpoints.js";
import "./parts/pinch.js";
import "./parts/quiz.js";
import "./parts/forms.js";
import "./parts/articles.js";