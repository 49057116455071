document.addEventListener('click', (e) => {
	let el = e.target;
	if (el && el.classList.contains('more') && el.closest('article')) {
		e.preventDefault();
		let article = el.closest('article');
		article.classList.toggle('opened');
		return false;
	}
});

function resizeTicker(func) {
	var timer;
	return function(event) {
		if (timer) clearTimeout(timer);
		timer = setTimeout(func, 100, event);
	};
}

function checkHasMore() {

	let articles = document.querySelectorAll('article') || [];
	articles.forEach((article) => {
		let div = article.querySelector('header + div');
		let divHeightClosed = div.offsetHeight;
		article.classList.toggle('opened');
		let divHeightOpened = div.offsetHeight;
		article.classList.toggle('opened');
		if (divHeightClosed < divHeightOpened) {
			article.classList.add('has-more');
		} else {
			article.classList.remove('has-more');
		}
	});
}

window.addEventListener("resize", resizeTicker(checkHasMore));

checkHasMore();