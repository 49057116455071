document.addEventListener('focusin', (e) => {
	let el = e.target;
	if (el && el.closest('.form-group')) {
		let fg = el.closest('.form-group');
		if (fg) {
			fg.classList.add('focus');
		}
	}
});

document.addEventListener('focusout', (e) => {
	let el = e.target;
	if (el && el.closest('.form-group')) {
		let fg = el.closest('.form-group');
		if (fg) {
			fg.classList.remove('focus');
		}
	}
});

document.addEventListener('change', (e) => {
	let el = e.target;
	if (el && el.closest('.form-group.invalid')) {
		el.closest('.form-group').classList.remove('invalid');
	}
});

document.addEventListener('submit', function(e){
	e.preventDefault();
	let el = e.target;
	
	let invalid = el.querySelectorAll(".form-group.invalid");
	invalid.forEach((fg) => {
		fg.classList.remove('invalid');
	});

	let error = false;

	let fgs = el.querySelectorAll(".form-group");
	fgs.forEach((fg) => {
		let checkbox = fg.querySelector("input[type='checkbox']");
		let input = fg.querySelector("input:not([type='checkbox'])");
		const email_regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const phone_regex = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;
		if (
			(checkbox && !checkbox.checked && checkbox.attributes.required) ||
			(input && !input.value && input.attributes.required) ||
			(input && input.type == 'email' && input.value && !email_regex.test(input.value)) ||
			(input && input.type == 'tel' && input.value && !phone_regex.test(input.value))
		)
		{
			fg.classList.add('invalid');
			error = true;
		}
	});

	if (error)
		return false;
	
	let button = el.querySelector("button.btn");
	button.classList.add('disabled');

	let formData = new FormData(el);

	const response = fetch("/apply/", {
		method: 'POST',
		cache: 'no-cache',
		redirect: 'follow',
		body: formData
	});
	
	let status = document.getElementById('status');
	status.innerHTML = "";

	response.then(
		response => {
			if (response.ok) {
				response.text().then((response) => {
					console.log(response);
					if (response === "OK") {
						try { ym(79011427, 'reachGoal', 'FORM-SEND'); } catch(e) {}
						window.location.href = el.dataset.url;
					}
					else {
						status.innerHTML = "Что-то пошло не так... " + response;
						button.classList.remove('disabled');
					}
				});
			} else {
				status.innerHTML = "Отправка не удалась, попробуйте еще раз.";
				button.classList.remove('disabled');
			}
		},
		error => {
			status.innerHTML = "Отправка не удалась, попробуйте еще раз.";
			button.classList.remove('disabled');
		}
	);

	return false;
});
